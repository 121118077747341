export default function customStyleFnEditorComments(style) {
  const output = {};
  const editorCommentKeys = style.filter((value) => value.startsWith('COMMENT'));
  if (editorCommentKeys.size) {
    const resolvedEditorCommentKeys = editorCommentKeys.filter((value) =>
      value.endsWith('RESOLVED'),
    );
    if (
      resolvedEditorCommentKeys.size ===
      editorCommentKeys.size - resolvedEditorCommentKeys.size
    ) {
      output.backgroundColor = 'transparent';
    } else {
      output.backgroundColor = 'rgba(255,212,0,0.14)';
    }
    output.borderBottom = '2px solid #fff';
  }
  return output;
}
