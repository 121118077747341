import { animateScroll } from 'react-scroll';
import getOffsetKeysInlineStyle from '@/editor/utils/getOffsetKeysInlineStyle';

const CONTAINER_ID = 'editor-scroll-container';

/**
 * @param {object} editorComment
 * @param {object} editorState
 */
export default function scrollToEditorComment(editorComment, editorState) {
  if (editorComment.ranges) {
    const offsetKeys = getOffsetKeysInlineStyle(editorComment, editorState);
    if (offsetKeys.length > 0) {
      const bodyRect = document.body.getBoundingClientRect();
      const scrollContainer = document.getElementById(CONTAINER_ID);

      const currentEditorCommentDomNodes = [];
      offsetKeys.forEach((offsetKey) => {
        currentEditorCommentDomNodes.push(
          document.querySelector(`span[data-offset-key="${offsetKey}"]`),
        );
      });
      const rect = currentEditorCommentDomNodes[0].getBoundingClientRect();

      if (scrollContainer) {
        const offset = scrollContainer.getBoundingClientRect().top;

        animateScroll.scrollTo(
          scrollContainer.scrollTop + rect.top - offset - window.innerHeight / 3,
          {
            offset,
            containerId: CONTAINER_ID,
          },
        );
      } else {
        animateScroll.scrollTo(rect.top - bodyRect.top - window.innerHeight / 3);
      }
    }
  }
}
