import { EditorState, Modifier, SelectionState } from 'draft-js';

/**
 * @param {object} editorState
 * @returns {object}
 */
export default function handleEditorDeleteKeyCommand(editorState) {
  const selection = editorState.getSelection();
  if (!selection.isCollapsed()) {
    return null;
  }

  const content = editorState.getCurrentContent();
  const startKey = selection.getStartKey();
  const block = content.getBlockForKey(startKey);
  const length = block.getLength();

  // The cursor is somewhere within the text. Behave normally.
  if (selection.getStartOffset() < length) {
    return null;
  }

  const blockBefore = content.getBlockBefore(startKey);
  const blockAfter = content.getBlockAfter(startKey);

  // We are at the end of the document. Behave normally.
  if (!blockAfter) {
    return null;
  }

  // remove the atomic block instead of moving the block below into the atomic block
  if (block.getType() === 'atomic' && length === 0) {
    const targetRange = new SelectionState({
      anchorKey: blockBefore.key,
      anchorOffset: blockBefore.getLength(),
      focusKey: block.key,
      focusOffset: block.getLength(),
    });
    const withoutCurrentBlock = Modifier.removeRange(content, targetRange, 'forward');
    const newState = EditorState.push(editorState, withoutCurrentBlock, 'remove-range');
    const endRange = new SelectionState({
      anchorKey: blockAfter.key,
      anchorOffset: 0,
      focusKey: blockAfter.key,
      focusOffset: 0,
    });
    return EditorState.forceSelection(newState, endRange);
  }

  // No custom block below. Behave normally.
  if (blockAfter.getType() !== 'atomic') {
    return null;
  }

  // we're about to delete an atomic block
  const atomicBlockTarget = selection.merge({
    focusKey: blockAfter.getKey(),
    focusOffset: blockAfter.getLength(),
  });

  const withoutAtomicBlock = Modifier.removeRange(content, atomicBlockTarget, 'forward');

  if (withoutAtomicBlock !== content) {
    // if current block contains content move selection to block below
    if (length > 0) {
      const endRange = new SelectionState({
        anchorKey: blockAfter.key,
        anchorOffset: 0,
        focusKey: blockAfter.key,
        focusOffset: 0,
      });
      return EditorState.forceSelection(editorState, endRange);
    }
    // if current block is empty remove it
    const targetRange = new SelectionState({
      anchorKey: blockBefore.key,
      anchorOffset: blockBefore.getLength(),
      focusKey: block.key,
      focusOffset: block.getLength(),
    });
    const withoutCurrentBlock = Modifier.removeRange(content, targetRange, 'forward');
    const newState = EditorState.push(editorState, withoutCurrentBlock, 'remove-range');
    const endRange = new SelectionState({
      anchorKey: blockAfter.key,
      anchorOffset: 0,
      focusKey: blockAfter.key,
      focusOffset: 0,
    });
    return EditorState.forceSelection(newState, endRange);
  }

  return null;
}
