/**
 * @param {object} data
 * @param {Array} data.editorComments
 * @param {string} data.selectedCommentEditorKey
 * @param {Array} data.comments
 * @returns {boolean}
 */
export default function isTempEditorCommentCleanOnSelection({
  editorComments,
  selectedCommentEditorKey,
  comments,
}) {
  const tempComment = editorComments.find((editorComment) =>
    comments.every((c) => c.editor_key !== editorComment.key),
  );
  const selectedEditorComment = editorComments.find((c) => c.key === selectedCommentEditorKey);

  return tempComment && selectedEditorComment && selectedEditorComment.key !== tempComment.key;
}
