import { Icon } from '@iconify-icon/react';
import { useEffect, useMemo, useState, type JSX } from 'react';
import { createPortal } from 'react-dom';
import RichEditor from '@/storychief/components/editors/RichEditor';
import useEditorCollaboration from '@/editor/hooks/useEditorCollaboration';
import Button from '@/storychief/components/Button';
import { useEmitEvent } from '@/storychief/hooks';
import StoryChief from '@/storychief';
import getPusherListener from '@/pusher/utils/getPusherListener';
import getEditorCollaborationStorage from './utils/getEditorCollaborationStorage';
import setEditorCollaborationStorage from './utils/setEditorCollaborationStorage';
import { EDITOR_COLLABORATION_EVENT, EDITOR_COLLABORATION_WHISPER } from './constants/Constants';

function EditorScBlockCollaboration(): JSX.Element {
  // Hooks
  const {
    blockTarget,
    portalTarget,
    contentJSON,
    setContentJSON,
    modelId,
    modelType,
    setPortalTarget,
    setBlockTarget,
  } = useEditorCollaboration();
  const emitFinishedEditingBlock = useEmitEvent(
    EDITOR_COLLABORATION_EVENT.emitFinishedEditingBlock,
  );

  // States
  const [forceUpdate, setForceUpdate] = useState(false);
  const [hasRestoredChanges, setHasRestoredChanges] = useState(false);

  // Variables
  const listener = getPusherListener(modelType, modelId);

  // Effects
  useEffect(() => emitFinishedEditingBlock({ user: StoryChief.user }), []);

  // Memo
  const conflictedBlock = useMemo(() => {
    const savedBlocks = getEditorCollaborationStorage(modelType);

    if (savedBlocks && contentJSON) {
      const parsed = JSON.parse(savedBlocks)[modelId];

      if (parsed) {
        return parsed[blockTarget] || null;
      }
    }

    return null;
  }, [blockTarget]);

  // Functions
  function resetInitialState(): void {
    setContentJSON(null);
    setPortalTarget(null);
    setBlockTarget(null);
  }

  function handleOnSave(): void {
    deleteBlockFromLocalStorage();

    listener.whisper(EDITOR_COLLABORATION_WHISPER.savedBlockChanges, {
      block: blockTarget,
      content: contentJSON,
      userId: StoryChief.user.id,
    });

    resetInitialState();

    listener.whisper(EDITOR_COLLABORATION_WHISPER.finishedEditingBlock, {
      block: blockTarget,
    });
  }

  function handleOnCancel(): void {
    deleteBlockFromLocalStorage();

    resetInitialState();

    emitFinishedEditingBlock({ user: StoryChief.user });
  }

  function deleteBlockFromLocalStorage(): void {
    const savedBlocks = getEditorCollaborationStorage(modelType);

    if (savedBlocks) {
      const allBlocks = JSON.parse(savedBlocks);

      if (allBlocks[modelId]) {
        const modelBlocks = allBlocks[modelId];

        delete modelBlocks[blockTarget];

        if (Object.keys(modelBlocks).length === 0) {
          delete allBlocks[modelId];
        }

        setEditorCollaborationStorage(modelType, JSON.stringify(allBlocks));
      }
    }
  }

  function handleRestoreUnsavedChanges(): void {
    setForceUpdate(true);

    setContentJSON(conflictedBlock);
    setTimeout(() => {
      setForceUpdate(false);
    }, 1);

    deleteBlockFromLocalStorage();
    setHasRestoredChanges(true);
  }

  function getIsEditorEmpty(): boolean {
    return contentJSON?.blocks.every((block) => block.type !== 'atomic' && !block.text);
  }

  // Render
  if (!portalTarget?.current || !contentJSON) {
    return null;
  }

  return createPortal(
    <div className="editor-block-collaboration">
      <div className="text-sm font-normal">
        <RichEditor
          disabled={forceUpdate}
          content={contentJSON}
          updateContent={setContentJSON}
          formControl
          formControlAutoHeight
          hasFooter
        />

        <div className="absolute bottom-0.5 left-2 right-4 flex items-center justify-between bg-white p-1.5">
          <div className="flex gap-2">
            <Button variant="secondary" size="sm" onClick={handleOnCancel}>
              Cancel
            </Button>
            <Button size="sm" onClick={handleOnSave} data-testid="block-collaboration-save-button">
              {getIsEditorEmpty() ? 'Remove block' : 'Save'}
            </Button>
          </div>

          {conflictedBlock && !hasRestoredChanges && (
            <Button variant="minimal" size="xs" onClick={handleRestoreUnsavedChanges}>
              <Icon width="16" className="mr-1" icon="ic:baseline-restore" inline /> Restore unsaved
              changes
            </Button>
          )}
        </div>
      </div>
    </div>,
    portalTarget.current,
  );
}

export default EditorScBlockCollaboration;
