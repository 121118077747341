import { uniqueId } from 'lodash-es';
import PropTypes from 'prop-types';
import { Icon } from '@iconify-icon/react';
import OverlayTooltip from '@/storychief/components/OverlayTooltip';
import StoryChief from '@/storychief';

const propTypes = {
  isNavDisabled: PropTypes.bool.isRequired,
  onResolve: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
};
const defaultProps = {};

function EditorCommentsHeader({ isNavDisabled, onResolve, onCancel, onNavigate }) {
  const isCurrentUserGuest = !StoryChief?.user?.id;

  return (
    <div className="sc-editor-comments__header d-flex flex-space-between space-1">
      <div className="d-flex">
        <OverlayTooltip id={uniqueId('sc-editor-action-')} content="Previous">
          <button
            className="btn-chromeless"
            type="button"
            disabled={isNavDisabled}
            aria-label="Previous comment"
            onClick={() => onNavigate('back')}
          >
            <em className="icon-angle-left" />
          </button>
        </OverlayTooltip>
        <OverlayTooltip id={uniqueId('sc-editor-action-')} content="Next">
          <button
            className="btn-chromeless"
            type="button"
            disabled={isNavDisabled}
            aria-label="Next comment"
            onClick={() => onNavigate('next')}
          >
            <em className="icon-angle-right" />
          </button>
        </OverlayTooltip>
      </div>

      <div className="d-flex">
        {!isCurrentUserGuest && (
          <OverlayTooltip id={uniqueId('sc-editor-action-')} content="Resolve">
            <button
              className="btn-chromeless"
              type="button"
              aria-label="Resolve"
              onClick={onResolve}
            >
              <Icon icon="custom:check-circled-o" inline className="mx-1" />
            </button>
          </OverlayTooltip>
        )}

        <OverlayTooltip id={uniqueId('sc-editor-action-')} content="Close">
          <button className="btn-chromeless" type="button" aria-label="Close" onClick={onCancel}>
            <em className="icon-cancel" />
          </button>
        </OverlayTooltip>
      </div>
    </div>
  );
}

EditorCommentsHeader.propTypes = propTypes;
EditorCommentsHeader.defaultProps = defaultProps;

export default EditorCommentsHeader;
