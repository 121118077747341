import { useEffect, useRef, useState } from 'react';
import { EditorState, Modifier, SelectionState } from 'draft-js';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ToolbarBlock from '../toolbar/ToolbarBlock';
import getSelectedBlockNode from '../../utils/getSelectedBlockNode';
import AssistantDropdown from '../../../assistant/components/AssistantDropdown';
import useAssistant from '@/assistant/hooks/useAssistant';
import { COMMANDS } from '@/assistant/constants/Constants';
import { BLOCK_BUTTONS } from '@/editor/constants/Constants';

const propTypes = {
  getEditorState: PropTypes.func.isRequired,
  editorState: PropTypes.shape({
    getCurrentContent: PropTypes.func.isRequired,
    getSelection: PropTypes.func.isRequired,
  }).isRequired,
  setEditorState: PropTypes.func.isRequired,
  setReadOnly: PropTypes.func.isRequired,
  focus: PropTypes.func.isRequired,
  plugins: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  blockButtons: PropTypes.arrayOf(PropTypes.shape({})),
  isPrivate: PropTypes.bool,
  toggleBlockType: PropTypes.func,
  toolbarsContainer: PropTypes.shape({
    getBoundingClientRect: PropTypes.func,
  }),
};
const defaultProps = {
  isPrivate: false,
  blockButtons: BLOCK_BUTTONS,
  toggleBlockType: () => {},
  toolbarsContainer: null,
};

function Sidebar({
  getEditorState,
  editorState,
  setEditorState,
  setReadOnly,
  focus,
  isPrivate,
  plugins,
  blockButtons,
  toggleBlockType,
  toolbarsContainer,
}) {
  // Hooks
  const { initCommand, command } = useAssistant();
  const [style, setStyle] = useState({});
  const [visible, setVisible] = useState(false);
  const [isSmallVariant, setIsSmallVariant] = useState(false);
  const toolbarRef = useRef();
  const prevContainerWidth = useRef();

  // Effects
  useEffect(() => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    // Hide sidebar when a selection is present in current block
    if (!selectionState.isCollapsed() || selectionState.anchorKey !== selectionState.focusKey) {
      hideSidebar();
      return;
    }
    const block = contentState.getBlockForKey(selectionState.anchorKey);
    if (block.getLength() === 1 && block.getText() === ' ' && !command) {
      const targetRange = new SelectionState({
        anchorKey: block.key,
        anchorOffset: 0,
        focusKey: block.key,
        focusOffset: 1,
      });

      const withoutText = Modifier.removeRange(contentState, targetRange, 'backward');
      const newState = EditorState.push(editorState, withoutText, 'remove-range');
      initCommand(COMMANDS.CHAT);
      setEditorState(newState);
      return;
    }
    if (!block || block.getLength() > 0 || block.getType() !== 'unstyled') {
      hideSidebar();
      return;
    }
    if (block.getLength() === 0) {
      if (block.getType() === 'unstyled') {
        setTimeout(setSidebarPosition, 0);
        return;
      }
      hideSidebar();
    }
  }, [editorState]);

  useEffect(() => {
    const editorContainer = toolbarRef.current.closest('.editor-container');
    const resizeObserver = new ResizeObserver(() => {
      const editorContainerRect = editorContainer.getBoundingClientRect();

      // Check variant only when the container width changes to avoid toolbar jumping on state change.
      if (prevContainerWidth.current !== editorContainerRect.width) {
        checkVariant();
        prevContainerWidth.current = editorContainerRect.width;
      }
    });
    resizeObserver.observe(editorContainer);

    return () => {
      resizeObserver.unobserve(editorContainer);
    };
  }, []);

  // Functions
  function checkVariant() {
    if (!toolbarRef.current) return;

    const editorContainer = toolbarRef.current.closest('.editor-container');
    const placeholder = editorContainer.querySelector('.public-DraftEditorPlaceholder-root');

    if (placeholder) {
      setTimeout(() => {
        const placeholderInner = placeholder.querySelector('.public-DraftEditorPlaceholder-inner');
        const placeholderRect = placeholder.getBoundingClientRect();
        const placeholderInnerRect = placeholderInner.getBoundingClientRect();
        const toolbarRect = toolbarRef.current.getBoundingClientRect();
        const hasText = editorState.getCurrentContent().hasText();

        setIsSmallVariant(
          !hasText && placeholderRect.width < toolbarRect.width + placeholderInnerRect.width,
        );
      }, 0);
    } else {
      setIsSmallVariant(false);
    }
  }

  function setSidebarPosition() {
    const selectedBlockNode = getSelectedBlockNode(editorState);
    if (!selectedBlockNode) {
      return;
    }
    // Hide sidebar when block is Figure (additional check is needed)
    // or block is smaller then editor (next to a left/right aligned block)
    // or if block is hidden (when assistant placeholders are visible)
    if (
      selectedBlockNode.tagName === 'FIGURE' ||
      selectedBlockNode.tagName === 'H1' ||
      selectedBlockNode.offsetWidth < selectedBlockNode.parentNode.offsetWidth ||
      selectedBlockNode.clientHeight === 0
    ) {
      hideSidebar();
      return;
    }
    const selectedBlockNodeRect = selectedBlockNode.getBoundingClientRect();
    const toolbarsContainerRect = toolbarsContainer.getBoundingClientRect();

    setVisible(true);
    checkVariant();
    setStyle({
      top: selectedBlockNodeRect.top - toolbarsContainerRect.top - 5,
      right: 0,
    });
  }

  function hideSidebar() {
    if (visible) {
      setVisible(false);
    }
  }

  // Render
  return (
    <div
      className={classNames(
        'side-toolbar',
        { 'side-toolbar-visible': visible },
        { 'side-toolbar-sm': isSmallVariant },
      )}
      style={style}
      ref={toolbarRef}
    >
      <AssistantDropdown
        getEditorState={getEditorState}
        setEditorState={setEditorState}
        includeMenu={false}
        className="sb-button"
      />
      {plugins.map((plugin) => {
        const Button = plugin.buttonComponent;
        return (
          <Button
            key={plugin.type}
            getEditorState={getEditorState}
            setEditorState={setEditorState}
            setReadOnly={setReadOnly}
            focus={focus}
            isPrivate={isPrivate}
          />
        );
      })}
      <div className="RichEditor-controls-separator" />
      {blockButtons.length > 0 ? (
        <ToolbarBlock
          getEditorState={getEditorState}
          onToggle={toggleBlockType}
          buttons={blockButtons}
          showTooltips
        />
      ) : null}
    </div>
  );
}

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

export default Sidebar;
