import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Editor,
  EditorState,
  ContentState,
  Modifier,
  convertFromRaw,
  SelectionState,
} from 'draft-js';
import classNames from 'classnames';
import convertAsciiQuotesToSmartQuotes from '@/editor/utils/convertAsciiQuotesToSmartQuotes';
import { usePrevious } from '@/storychief/hooks';
import Toolbar from '@/editor/components/toolbar/Toolbar';
import customStyleFnEditorComments from '@/editor/utils/customStyleFnEditorComments';
import updateTitleEditorState, {
  updateTitleEditorStateAsync,
} from '@/editor/actions/updateTitleEditorState';
import getDefaultDecorator from '@/editor/decorators/getDefaultDecorator';
import { getTitleEditorComments } from '@/editor/selectors';
import scrollToEditorComment from '@/editor/utils/scrollToEditorComment';
import syncEditorComments from '@/editor/utils/syncEditorComments';
import getSelectionText from '@/editor/utils/getSelectionText';
import AssistantProvider from '@/assistant/components/AssistantProvider';
import getCurrentBlock from '../utils/getCurrentBlock';
import BlockToolbarAssistant from './blocks/BlockToolbarAssistant';
import useSelectedComment from '@/comments/hooks/useSelectedComment';
import useComments from '@/comments/hooks/useComments';
import selectedEditorCommentHighlight from '@/editor/utils/selectedEditorCommentHighlight';
import selectedEditorCommentsClean from '@/editor/utils/selectedEditorCommentsClean';
import prependNewBlockToContentEditorAction from '@/editor/actions/prependNewBlockToContentEditor';
import EditorComments from '@/editor/components/EditorComments';
import tempEditorCommentCleanAction from '@/editor/actions/cleanAllTempEditorComments';
import toggleCommentInlineStyleAction from '@/editor/actions/toggleCommentInlineStyle';

const propTypes = {
  placeholder: PropTypes.string,
  modelId: PropTypes.string.isRequired,
  modelType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  contentEditorNode: PropTypes.shape({
    focus: PropTypes.func,
  }),
  sidebars: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  preview: PropTypes.bool,
  updateTitleEditorStateAsync: PropTypes.func.isRequired,
  updateTitleEditorState: PropTypes.func.isRequired,
  prependNewBlockToContentEditor: PropTypes.func.isRequired,
  cleanAllTempEditorComments: PropTypes.func.isRequired,
  toggleCommentInlineStyle: PropTypes.func.isRequired,
  editorState: PropTypes.shape({
    getSelection: PropTypes.func,
    getCurrentContent: PropTypes.func,
  }),
  titleEditorComments: PropTypes.arrayOf(PropTypes.shape({})),
  assistantSubject: PropTypes.shape({
    type: PropTypes.string,
    component: PropTypes.string,
    hasSelectedText: PropTypes.bool,
    isEmpty: PropTypes.bool,
  }),
  onEditorStateChange: PropTypes.func,
  commentsPositionMode: PropTypes.oneOf(['editor', 'comment']),
  singularContentType: PropTypes.string,
};

const defaultProps = {
  placeholder: 'Title',
  disabled: false,
  preview: false,
  editorState: EditorState.createEmpty(getDefaultDecorator()),
  contentEditorNode: null,
  titleEditorComments: null,
  assistantSubject: undefined,
  onEditorStateChange: () => {},
  commentsPositionMode: undefined,
  singularContentType: null,
};

const MAX_CHAR_LENGTH = 1500;

function EditorScTitle(props) {
  // Refs
  const editorNode = useRef();
  const toolbarsContainerNode = useRef();

  // Variables
  const isFocused = props.editorState.getSelection().getHasFocus();
  const currentBlock = getCurrentBlock(props.editorState);
  let contentLength = 0;
  if (isFocused) {
    contentLength = getContentLength(props.editorState);
  }
  const hasComments = props.titleEditorComments?.length > 0;

  // Hooks
  const { comments } = useComments();
  const { selectedCommentEditorKey, setSelectedCommentEditorKey } = useSelectedComment(comments);
  const selectedEditorComment = props.titleEditorComments.find(
    (t) => t.key === selectedCommentEditorKey,
  );
  const isCommentsSidebarOpen = props.sidebars.data.some((s) => s.id === 'CommentsSidebar');
  const location = useLocation();

  const prevTitle = usePrevious(props.title);
  const prevDisabled = usePrevious(props.disabled);
  const prevSelectedCommentEditorKey = usePrevious(selectedCommentEditorKey);

  useEffect(() => {
    let newEditorState = syncEditorComments(props.editorState, props.titleEditorComments, comments);

    if (!props.disabled) {
      const contentState = newEditorState.getCurrentContent();
      const titleBlock = contentState.getBlockMap().get('title');

      if (titleBlock) {
        const endRange = new SelectionState({
          anchorKey: titleBlock.key,
          anchorOffset: titleBlock.getLength(),
          focusKey: titleBlock.key,
          focusOffset: titleBlock.getLength(),
        });
        newEditorState = EditorState.forceSelection(newEditorState, endRange);
      } else {
        newEditorState = EditorState.forceSelection(newEditorState, newEditorState.getSelection());
      }
    }

    props.updateTitleEditorStateAsync(newEditorState);
  }, []);

  useEffect(() => {
    // When the editor is disabled or in preview mode, the content may be changed outside
    // the editor (eg. when restoring an older version). In such cases, we need to sync the
    // editor state with the new content.
    if ((props.disabled || prevDisabled || props.preview) && prevTitle !== props.title) {
      refreshEditorState(props.title);
    }
  }, [props.title, props.disabled, props.preview]);

  useEffect(() => {
    if (props.singularContentType) {
      const contentHasChanged = JSON.stringify(prevTitle) !== JSON.stringify(props.title);

      if (contentHasChanged) {
        refreshEditorState(props.title, true);
      }
    }
  }, [location]);

  useEffect(() => {
    if (hasComments) {
      selectedEditorCommentsClean(editorNode.current.editorContainer);
      selectedEditorCommentHighlight({
        selectedEditorComment,
        editorState: props.editorState,
        isCommentsSidebarOpen,
      });
    }
  }, null); // Because we are updating the DOM, we need to run this on every render.

  useEffect(() => {
    if (hasComments) {
      scrollToSelectedComment();
    }
  }, [selectedEditorComment, selectedCommentEditorKey]);

  // Functions
  function hasActiveCommentChanged() {
    return prevSelectedCommentEditorKey !== selectedCommentEditorKey;
  }

  function scrollToSelectedComment() {
    if (
      selectedEditorComment &&
      hasActiveCommentChanged() &&
      !props.editorState.getSelection().getHasFocus()
    ) {
      scrollToEditorComment(selectedEditorComment, props.editorState);
    }
  }

  function onChange(changedEditorState) {
    if (props.editorState.getCurrentContent() !== changedEditorState.getCurrentContent()) {
      props.onEditorStateChange(changedEditorState);
    }
    props.updateTitleEditorState(changedEditorState);
  }

  function refreshEditorState(newContent, onlyRefreshInterface) {
    let editorState;

    if (newContent !== '') {
      editorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(newContent)),
        getDefaultDecorator(),
      );
    } else {
      editorState = EditorState.createEmpty(getDefaultDecorator());
    }

    if (!onlyRefreshInterface) {
      props.onEditorStateChange(editorState);
    }

    props.updateTitleEditorState(editorState);
  }

  function getContentLength(editorState) {
    return editorState.getCurrentContent().getPlainText('').length;
  }

  function handleReturn() {
    if (props.preview || props.disabled) {
      return true;
    }

    // Do not prepend a new block content editor is empty.
    if (props.content) {
      props.prependNewBlockToContentEditor();
    }

    // Focus after the editor state in the store is updated.
    setTimeout(() => {
      if (props.contentEditorNode) {
        props.contentEditorNode.focus();
      }
    }, 1);

    return 'handled';
  }

  function handleBeforeInput(chars) {
    if (props.preview || props.disabled) {
      return 'handled';
    }

    const newEditorState = convertAsciiQuotesToSmartQuotes(chars, props.editorState);

    const contentStateLength = getContentLength(newEditorState);
    const selectionLength = getSelectionText(newEditorState).length;
    if (contentStateLength - selectionLength > MAX_CHAR_LENGTH - 1) {
      return 'handled';
    }

    if (props.editorState !== newEditorState) {
      onChange(newEditorState);
      return 'handled';
    }

    return 'not-handled';
  }

  function handlePastedText(text) {
    // Ignore non-text (eg. images) clipboard objects or when editor is not editable.
    if (text === null || props.preview || props.disabled) {
      return false;
    }

    const NEWLINE_REGEX = /\n/g;
    let newText = text.replace(NEWLINE_REGEX, ' ').trim();

    const selectionLength = getSelectionText(props.editorState).length;
    const currentTotal = contentLength - selectionLength;

    if (currentTotal === MAX_CHAR_LENGTH) {
      return 'handled';
    }

    if (currentTotal + newText.length > MAX_CHAR_LENGTH) {
      const limit = MAX_CHAR_LENGTH - currentTotal;
      newText = newText.slice(0, limit);
    }

    const blockMap = ContentState.createFromText(newText).blockMap;
    const newEditor = Modifier.replaceWithFragment(
      props.editorState.getCurrentContent(),
      props.editorState.getSelection(),
      blockMap,
    );
    onChange(EditorState.push(props.editorState, newEditor, 'insert-fragment'));

    return true;
  }

  function toggleInlineStyle(inlineStyle) {
    if (inlineStyle !== 'COMMENT') {
      return;
    }

    onChange(
      props.toggleCommentInlineStyle({
        editorState: props.editorState,
        editorComments: props.titleEditorComments,
        comments,
        setSelectedCommentEditorKey,
      }),
    );
  }

  function handleOnTempEditorCommentClean() {
    props.cleanAllTempEditorComments(comments);
  }

  return (
    <div className="editor-container" data-intercom-target="sc-editor-title">
      <div className="editor-toolbars" ref={toolbarsContainerNode}>
        <AssistantProvider
          getEditorState={() => props.editorState}
          setEditorState={onChange}
          subject={props.assistantSubject}
        >
          <Toolbar
            toolbarsContainer={toolbarsContainerNode.current}
            editorState={props.editorState}
            setEditorState={onChange}
            toggleInlineStyle={toggleInlineStyle}
            editorEnabled={!props.disabled}
            preview={props.preview}
            focus={() => editorNode.current.focus()}
            setLink={() => null}
            toggleBlockType={() => null}
            blockButtons={[]}
            inlineButtonsRight={[]}
            showToolbarHyperlink={false}
            handleCommandShowToolbarHyperlink={() => null}
          />
          {currentBlock && (
            <BlockToolbarAssistant
              getEditorNode={() => editorNode.current}
              currentBlock={currentBlock}
            />
          )}
        </AssistantProvider>
        <EditorComments
          modelType={props.modelType}
          modelId={props.modelId}
          editorComments={props.titleEditorComments}
          onTempEditorCommentClean={handleOnTempEditorCommentClean}
          positionMode={props.commentsPositionMode}
        />
      </div>

      <Editor
        ref={editorNode}
        readOnly={props.disabled}
        webDriverTestID="cy_editor_title"
        editorState={props.editorState}
        getEditorState={() => props.editorState}
        onChange={onChange}
        customStyleFn={customStyleFnEditorComments}
        handleReturn={handleReturn}
        handlePastedText={handlePastedText}
        handleBeforeInput={handleBeforeInput}
        stripPastedStyles
        placeholder={props.placeholder}
      />

      {isFocused && MAX_CHAR_LENGTH - contentLength < 500 && (
        <div className="editor-char-count-wrapper">
          <div className="editor-char-count">
            <span
              className={classNames('small', {
                'text-muted': contentLength !== MAX_CHAR_LENGTH,
                'text-warning': contentLength === MAX_CHAR_LENGTH,
              })}
            >
              <span>{`${contentLength}/${MAX_CHAR_LENGTH}`}</span>
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

EditorScTitle.propTypes = propTypes;
EditorScTitle.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    contentEditorNode: state.editor.editorNode,
    editorState: state.editor.titleEditorState,
    titleEditorComments: getTitleEditorComments(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateTitleEditorState,
      updateTitleEditorStateAsync,
      prependNewBlockToContentEditor: prependNewBlockToContentEditorAction,
      cleanAllTempEditorComments: tempEditorCommentCleanAction,
      toggleCommentInlineStyle: toggleCommentInlineStyleAction,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditorScTitle);
