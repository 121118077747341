import { EditorState, RichUtils } from 'draft-js';
import generateRandomKey from 'draft-js/lib/generateRandomKey';
import getEditorStateWithoutTempComment from '@/editor/utils/getEditorStateWithoutTempComment';
import scrollSidebarToSelectedComment from '@/comments/utils/scrollSidebarToSelectedComment';
import cleanAllTempEditorComments from '@/editor/actions/cleanAllTempEditorComments';

function moveEditorSelectionToEnd(editorState) {
  const selection = editorState.getSelection();

  return selection.merge({
    anchorOffset: selection.getFocusOffset(),
    anchorKey: selection.getFocusKey(),
  });
}

export default function toggleCommentInlineStyle({
  editorState,
  editorComments,
  comments,
  setSelectedCommentEditorKey,
}) {
  return (dispatch) => {
    const commentInlineStyle = `COMMENT-${generateRandomKey()}`;
    const [cleanEditorState, hasTempCommentBeenRemoved] = getEditorStateWithoutTempComment(
      editorState,
      {
        editorComments,
        comments,
      },
    );
    const newEditorState = RichUtils.toggleInlineStyle(cleanEditorState, commentInlineStyle);

    // Remove the temporary comment in globally (in other editors) if it has not been removed from the current editor
    if (!hasTempCommentBeenRemoved) {
      dispatch(cleanAllTempEditorComments(comments));
    }
    setSelectedCommentEditorKey(commentInlineStyle);
    scrollSidebarToSelectedComment({ key: commentInlineStyle });

    return EditorState.forceSelection(newEditorState, moveEditorSelectionToEnd(newEditorState));
  };
}
