import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { escape, unescape } from 'lodash-es';
import Immutable from 'immutable';
import ToolbarButton from '../toolbar/ToolbarButton';
import updateDataOfBlock from '../../utils/updateDataOfBlock';
import { BLOCK_TOOLBAR_SETTINGS, BLOCK_TYPES } from '../../constants/Constants';
import { useDidUpdateEffect, usePrevious } from '@/storychief/hooks';
import useProcessImage from '@/media/hooks/useProcessImage';
import useModal from '@/modals/hooks/useModal';

const propTypes = {
  setEditorState: PropTypes.func.isRequired,
  getEditorState: PropTypes.func.isRequired,
  currentBlock: PropTypes.shape({
    getData: PropTypes.func.isRequired,
    getKey: PropTypes.func.isRequired,
  }).isRequired,
  editorSettings: PropTypes.shape({
    controls: PropTypes.shape({
      align_float: PropTypes.bool.isRequired,
      display_wide: PropTypes.bool.isRequired,
      display_full: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  focus: PropTypes.func.isRequired,
  toolbarsContainer: PropTypes.shape({
    getBoundingClientRect: PropTypes.func,
  }),
};
const defaultProps = {
  toolbarsContainer: null,
};

function BlockToolbar({
  setEditorState,
  focus,
  currentBlock,
  getEditorState,
  editorSettings,
  toolbarsContainer,
}) {
  // variables
  const blockData = currentBlock.getData();
  const blockKey = currentBlock.getKey();
  const blockDataType = blockData.get('type');
  const currentBlockDomNode =
    blockKey && document.querySelector(`.block[data-offset-key="${blockKey}-0-0"]`);

  // State
  const [showURLInput, setShowURLInput] = useState(false);
  const [urlInputValue, setUrlInputValue] = useState('');
  const [urlTargetBlank, setUrlTargetBlank] = useState(false);
  const [urlRelNofollow, setUrlRelNofollow] = useState(false);
  const [showAltInput, setShowAltInput] = useState(false);
  const [showTextButtonInput, setShowTextButtonInput] = useState(false);
  const [textButtonValue, setTextButtonValue] = useState(blockData.get('textButton') || '');
  const [altValue, setAltValue] = useState('');
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  // Refs
  const altinput = useRef();
  const urlinput = useRef();
  const settingsbarNode = useRef(null);

  // Hooks
  const imageEditModal = useModal('ImageEditModal');
  const imageGalleryModal = useModal('ImageGallery');

  const processImage = useProcessImage({
    uploadOnSave: true,
    saveToDatabase: true,
    isPrivate: blockData.get('isPrivate'),
    onTransformed: () => {
      updateData({
        uploadProgress: 50,
      });
    },
    onCompleted: (imageEdited) => {
      updateData({
        src: imageEdited.url,
        width: imageEdited.width,
        height: imageEdited.height,
        uploading: false,
        uploadProgress: 100,
      });
    },
  });
  const prevCurrentBlock = usePrevious(currentBlock);
  useDidUpdateEffect(() => {
    if (showAltInput) {
      altinput.current.focus();
      altinput.current.select();
    } else {
      // Delay editor focus after all other operations (issue since React 17).
      setTimeout(() => {
        focus();
      }, 50);
    }
  }, [showAltInput]);

  useDidUpdateEffect(() => {
    if (showURLInput) {
      urlinput.current.focus();
      urlinput.current.select();
    } else {
      // Delay editor focus after all other operations (issue since React 17).
      setTimeout(() => {
        focus();
      }, 50);
    }
  }, [showURLInput]);

  useDidUpdateEffect(() => {
    // check if block key has changed and hide Input fields
    if (prevCurrentBlock.getKey() !== blockKey) {
      setShowURLInput(false);
      setShowAltInput(false);
    }
  }, [blockKey]);

  useEffect(() => {
    if (!settingsbarNode.current || !currentBlockDomNode) {
      return null;
    }

    const editorContainerDom = document.querySelector('.editor-container');

    const observer = new ResizeObserver(() => {
      if (!settingsbarNode.current) {
        return;
      }

      const currentBlockRect = currentBlockDomNode.getBoundingClientRect();
      const toolbarsContainerRect = toolbarsContainer.getBoundingClientRect();
      const toolbarRect = settingsbarNode.current.getBoundingClientRect();

      setTop(currentBlockRect.y - toolbarsContainerRect.y - toolbarRect.height - 10);

      if (blockDataType === BLOCK_TYPES.button) {
        const { paddingLeft: editorContainerPaddingLeft } =
          window.getComputedStyle(editorContainerDom);

        const buttonDomNode = currentBlockDomNode.querySelector('a');

        if (buttonDomNode) {
          const buttonWidth = buttonDomNode.getBoundingClientRect().width;

          setLeft(
            parseInt(editorContainerPaddingLeft, 10) + buttonWidth / 2 - toolbarRect.width * 0.5,
          );
        }
      } else {
        setLeft(
          currentBlockRect.left +
            currentBlockRect.width * 0.5 -
            toolbarsContainerRect.x -
            toolbarRect.width * 0.5,
        );
      }
    });

    observer.observe(editorContainerDom);

    return () => observer.unobserve(editorContainerDom);
  }, [
    settingsbarNode,
    currentBlockDomNode,
    blockKey,
    blockData,
    showURLInput,
    showAltInput,
    showTextButtonInput,
  ]);

  if (
    !currentBlockDomNode ||
    (currentBlockDomNode && currentBlockDomNode.firstChild.className.includes('is-empty'))
  ) {
    return null;
  }

  function updateData(data) {
    if (currentBlock) {
      setEditorState(updateDataOfBlock(getEditorState(), currentBlock, data));
    }
  }

  function onClickSize(size) {
    updateData({
      size,
      alignment: '',
    });
  }

  function onClickAlignment(alignment) {
    updateData({
      alignment,
      size: 'regular',
    });
  }

  function onClickEdit() {
    switch (blockDataType) {
      case BLOCK_TYPES.image: {
        imageEditModal.toggle({
          props: {
            image: { url: blockData.get('src') },
            onCompleted: (editedImage, editedImageTransformData) => {
              processImage({
                originalImageUrl: blockData.get('src'),
                editedImage,
                editedImageTransformData,
              });
              updateData({
                uploading: true,
                uploadProgress: 0,
              });
            },
          },
        });
        break;
      }
      case BLOCK_TYPES.gallery: {
        // fix: data firstly returns a List after that it returns an array
        let images = blockData.get('images');
        if (Immutable.List.isList(images)) {
          images = images.toArray();
        }
        images = images.map((img) => {
          if (Immutable.Map.isMap(img)) {
            return img.toObject();
          }
          return img;
        });
        imageGalleryModal.toggle({
          props: {
            images: images.map((_image) => ({
              ..._image,
              url: _image.src,
            })),
            onSave: (_images) => {
              updateData({
                images: _images.map((_image) => ({
                  src: _image.url,
                  width: _image.width,
                  height: _image.height,
                  caption: _image.caption,
                  alt: _image.alt,
                })),
              });
            },
          },
        });
        break;
      }
      case BLOCK_TYPES.button: {
        setShowTextButtonInput(true);
        break;
      }
      default:
        break;
    }
  }

  function showLinkInput() {
    setShowURLInput(true);
    setUrlInputValue(unescape(blockData.get('href')));
    setUrlTargetBlank(!!blockData.get('targetBlank'));
    setUrlRelNofollow(!!blockData.get('relNofollow'));
  }

  function hideLinkInput(e) {
    e.preventDefault();
    setShowURLInput(false);
    setUrlInputValue('');
  }

  function showAltInputForm() {
    setShowAltInput(true);
    setAltValue(blockData.get('alt') ? unescape(blockData.get('alt')) : '');
  }

  function hideAltInputForm(e) {
    e.preventDefault();
    setShowAltInput(false);
    setAltValue('');
  }

  function getSizeButtons(width, size, alignment) {
    const { controls } = editorSettings;

    return (
      <div className="RichEditor-controls">
        {controls.display_full && (
          <ToolbarButton
            key="full"
            active={size === 'full'}
            onToggle={onClickSize}
            description="Full size"
            disabled={width && width < 2000}
            styleName="full"
            icon="custom:size-full"
          />
        )}
        {controls.display_wide && (
          <ToolbarButton
            key="large"
            active={size === 'large'}
            onToggle={onClickSize}
            description="Large size"
            disabled={width && width < 1000}
            styleName="large"
            icon="custom:size-large"
          />
        )}
        <ToolbarButton
          key="regular"
          active={size !== 'full' && size !== 'large' && !alignment}
          onToggle={onClickSize}
          description="Regular size"
          styleName="regular"
          icon="custom:size-regular"
        />
      </div>
    );
  }

  function handleSaveLinkInput() {
    let url = urlInputValue;
    if (!url) {
      url = '';
    }
    if (url !== '' && url.indexOf('http') === -1) {
      url = `http://${url}`;
    }
    updateData({
      href: escape(url),
      targetBlank: urlTargetBlank,
      relNofollow: urlRelNofollow,
    });
    setShowURLInput(false);
    setUrlInputValue('');
  }

  function handleLinkOnKeyDown(e) {
    if (e.which === 13) {
      e.preventDefault();
      handleSaveLinkInput();
    } else if (e.which === 27) {
      hideLinkInput(e);
    }
  }

  function handleLinkOnChange(e) {
    setUrlInputValue(e.target.value);
  }

  function handleSaveAltInput() {
    updateData({ alt: escape(altValue) });
    setShowAltInput(false);
    setAltValue('');
  }

  function handleSaveTextButtonInput() {
    updateData({ textButton: textButtonValue });
    setShowTextButtonInput(false);
  }

  function handleAltOnKeyDown(e) {
    if (e.which === 13) {
      e.preventDefault();
      handleSaveAltInput();
    } else if (e.which === 27) {
      hideAltInputForm(e);
    }
  }

  function handleTextButtonOnKeyDown(e) {
    if (e.which === 13) {
      e.preventDefault();
      handleSaveTextButtonInput();
    } else if (e.which === 27) {
      setShowTextButtonInput(false);
      setTextButtonValue('');
    }
  }

  function handleTextButtonOnChange({ target }) {
    setTextButtonValue(target.value);
  }

  function handleAltOnChange(e) {
    setAltValue(e.target.value);
  }

  function handleTargetCheckboxChange() {
    setUrlTargetBlank((prevState) => !prevState);
  }

  function handleRelCheckboxChange() {
    setUrlRelNofollow((prevState) => !prevState);
  }

  function getAlignmentButtons(alignment) {
    if (!editorSettings.controls.align_float) {
      return null;
    }

    return (
      <>
        <ToolbarButton
          key="indent-left"
          active={alignment === 'left'}
          onToggle={onClickAlignment}
          description="Align left"
          styleName="left"
          icon="custom:align-left"
        />
        <ToolbarButton
          key="indent-right"
          active={alignment === 'right'}
          onToggle={onClickAlignment}
          description="Align right"
          styleName="right"
          icon="custom:align-right"
        />
      </>
    );
  }

  function getEditText() {
    switch (blockDataType) {
      case BLOCK_TYPES.gallery: {
        return 'Edit gallery';
      }
      case BLOCK_TYPES.image: {
        return 'Edit image';
      }
      case BLOCK_TYPES.button: {
        return 'Edit text';
      }
      default:
        return 'Edit';
    }
  }

  function getIsImageEditDisabled() {
    return blockDataType === BLOCK_TYPES.image && blockData.get('src') === '';
  }

  const blockSettings = BLOCK_TOOLBAR_SETTINGS.find((b) => b.type === blockDataType);

  if (blockSettings) {
    if (showURLInput) {
      return (
        <div
          ref={settingsbarNode}
          className="editor-toolbar editor-toolbar__block editor-toolbar--isopen editor-toolbar--linkinput"
          style={{ left, top }}
        >
          <div className="RichEditor-controls RichEditor-show-input d-block">
            <form className="form-inline">
              <div className="form-group form-group-sm">
                <input
                  ref={urlinput}
                  type="text"
                  className="text form-control form-control--inverted form-control--chromeless"
                  onKeyDown={handleLinkOnKeyDown}
                  onChange={handleLinkOnChange}
                  placeholder="Enter link URL"
                  value={urlInputValue}
                />
              </div>
              <hr />
              <div className="form-group form-group-sm gutter-right-2">
                <label className="control-label" htmlFor="targetCheckboxBlock">
                  <input
                    type="checkbox"
                    id="targetCheckboxBlock"
                    name="targetBlock"
                    checked={urlTargetBlank}
                    onChange={handleTargetCheckboxChange}
                  />
                  <span className="gutter-left-1">New tab</span>
                </label>
              </div>
              <div className="form-group form-group-sm gutter-1">
                <label className="control-label" htmlFor="relCheckboxBlock">
                  <input
                    type="checkbox"
                    id="relCheckboxBlock"
                    name="relBlock"
                    checked={urlRelNofollow}
                    onChange={handleRelCheckboxChange}
                  />
                  <span className="gutter-left-1">No follow</span>
                </label>
              </div>
              <button
                type="button"
                className="url-input-save btn--rectangle btn btn-small btn-primary pull-right"
                onMouseDown={handleSaveLinkInput}
              >
                Save
              </button>
            </form>
          </div>
          <div className="arrow-clip">
            <div className="arrow" />
          </div>
        </div>
      );
    }
    if (showAltInput) {
      return (
        <div
          ref={settingsbarNode}
          className="editor-toolbar editor-toolbar__block editor-toolbar--isopen"
          style={{ left, top }}
        >
          <div className="RichEditor-controls RichEditor-show-input d-block">
            <div className="form-inline">
              <div className="form-group form-group-sm">
                <input
                  ref={altinput}
                  type="text"
                  className="text form-control form-control--inverted form-control--chromeless"
                  onKeyDown={handleAltOnKeyDown}
                  onChange={handleAltOnChange}
                  placeholder="Enter alt tag"
                  value={altValue}
                />
              </div>
              <button
                type="button"
                className="url-input-save btn btn-small btn--rectangle btn-primary"
                onMouseDown={handleSaveAltInput}
              >
                Save
              </button>
            </div>
          </div>
          <div className="arrow-clip">
            <div className="arrow" />
          </div>
        </div>
      );
    }
    if (showTextButtonInput) {
      return (
        <div
          ref={settingsbarNode}
          className="editor-toolbar editor-toolbar__block editor-toolbar--isopen"
          style={{ left, top }}
        >
          <div className="RichEditor-controls RichEditor-show-input d-block">
            <div className="form-inline">
              <div className="form-group form-group-sm">
                <input
                  ref={altinput}
                  type="text"
                  className="text form-control form-control--inverted form-control--chromeless"
                  onKeyDown={handleTextButtonOnKeyDown}
                  onChange={handleTextButtonOnChange}
                  placeholder="Enter button text"
                  value={textButtonValue}
                />
              </div>
              <button
                type="button"
                className="url-input-save btn btn-small btn--rectangle btn-primary"
                onMouseDown={handleSaveTextButtonInput}
              >
                Save
              </button>
            </div>
          </div>
          <div className="arrow-clip">
            <div className="arrow" />
          </div>
        </div>
      );
    }
    return (
      <div
        ref={settingsbarNode}
        className="editor-toolbar editor-toolbar--settings editor-toolbar--isopen"
        style={{ left, top }}
      >
        {blockSettings.size &&
          getSizeButtons(blockData.get('width'), blockData.get('size'), blockData.get('alignment'))}
        {blockSettings.alignment && getAlignmentButtons(blockData.get('alignment'))}
        {blockSettings.href && (
          <ToolbarButton
            key="link"
            active={blockData.has('href') && blockData.get('href') !== ''}
            onToggle={showLinkInput}
            description="Add a link"
            styleName="link"
            icon="fa:link"
          />
        )}
        {blockSettings.alt && (
          <ToolbarButton
            key="alt"
            active={blockData.has('alt') && blockData.get('alt') !== ''}
            onToggle={showAltInputForm}
            description="Add alt tag"
            styleName="alt"
            icon="fa:eye"
          />
        )}

        {blockSettings.edit && (
          <span>
            <div className="RichEditor-controls-separator" />
            {getIsImageEditDisabled() ? (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="edit-disabled-tooltip">Wait for image to upload</Tooltip>}
              >
                <span className="RichEditor-styleButton text-muted" role="button">
                  {getEditText()}
                </span>
              </OverlayTrigger>
            ) : (
              <button
                type="button"
                className="RichEditor-styleButton text-muted"
                onClick={onClickEdit}
              >
                {getEditText()}
              </button>
            )}
          </span>
        )}
        <div className="arrow-clip">
          <div className="arrow" />
        </div>
      </div>
    );
  }
  return null;
}

BlockToolbar.propTypes = propTypes;
BlockToolbar.defaultProps = defaultProps;

export default BlockToolbar;
