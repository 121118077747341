/**
 * @param {{ type: string, marks: Array }} analysisAssessment
 * @returns {Array<string>|null}
 */
export default function getEditorSeoHighlightBlocks({ type, marks }) {
  if (type === 'subheadingsDistributionTooLong') {
    const regex = /data-key="([^"]*)"/g;
    const highlightBlocks = [];

    marks.forEach((mark) => {
      let matchArr = regex.exec(mark.text);
      while (matchArr !== null) {
        highlightBlocks.push(matchArr[1]);
        matchArr = regex.exec(mark.text);
      }
    });

    return highlightBlocks;
  }

  return null;
}
