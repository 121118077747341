import { EditorState, RichUtils, Modifier, SelectionState } from 'draft-js';
import resetBlockWithType from '@/editor/utils/resetBlockWithType';

/**
 * @param {object} editorState
 * @returns {object}
 */
export default function handleEditorBackspaceKeyCommand(editorState) {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const startKey = selection.getStartKey();
  const block = content.getBlockForKey(startKey);
  const blockBefore = content.getBlockBefore(startKey);
  const blockBeforeData = blockBefore ? blockBefore.getData().toObject() : '';
  const blockAfter = content.getBlockAfter(startKey);

  if (
    blockBefore &&
    blockBefore.getType() === 'atomic' &&
    (blockBeforeData.type === 'divider' || blockBeforeData.type === 'columnDivider')
  ) {
    return RichUtils.handleKeyCommand(editorState, 'backspace');
  }

  // if atomic block without content reset to unstyled
  if (block.getType() === 'atomic' && block.getLength() === 0) {
    return resetBlockWithType(editorState);
  }
  // default draft behavior
  if (!selection.isCollapsed() || selection.getAnchorOffset() || selection.getFocusOffset()) {
    return null;
  }

  // First, try to select a preceding atomic block.
  if (blockBefore && blockBefore.getType() === 'atomic') {
    if (block.getLength() > 0) {
      // keep the current block and select the preceding atom
      const endRange = new SelectionState({
        anchorKey: blockBefore.key,
        anchorOffset: blockBefore.getLength(),
        focusKey: blockBefore.key,
        focusOffset: blockBefore.getLength(),
      });
      return EditorState.forceSelection(editorState, endRange);
    }
    const targetRange = new SelectionState({
      anchorKey: blockBefore.key,
      anchorOffset: blockBefore.getLength(),
      focusKey: block.key,
      focusOffset: block.getLength(),
    });
    const withoutCurrentBlock = Modifier.removeRange(content, targetRange, 'forward');
    const newState = EditorState.push(editorState, withoutCurrentBlock, 'remove-range');
    const endRange = new SelectionState({
      anchorKey: blockBefore.key,
      anchorOffset: blockBefore.getLength(),
      focusKey: blockBefore.key,
      focusOffset: blockBefore.getLength(),
    });
    return EditorState.forceSelection(newState, endRange);
  }

  // If that doesn't succeed, try to remove the current block style.
  const withoutBlockStyle = RichUtils.tryToRemoveBlockStyle(editorState);

  if (withoutBlockStyle) {
    return EditorState.push(editorState, withoutBlockStyle, 'change-block-type');
  }

  // Remove the first block if the first block is empty there is a block after it.
  if (!blockBefore && blockAfter && block.getLength() === 0) {
    // We cannot use .removeRange to remove the first block. We need to remove using blockMap.
    // Source: https://github.com/facebook/draft-js/issues/773#issuecomment-303961362
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();
    const newBlockMap = blockMap.remove(startKey);
    const newContentState = contentState.merge({
      blockMap: newBlockMap,
    });
    const newState = EditorState.push(editorState, newContentState, 'remove-range');

    // Change selection to the block after.
    const endRange = new SelectionState({
      anchorKey: blockAfter.key,
      anchorOffset: blockAfter.getLength(),
      focusKey: blockAfter.key,
      focusOffset: blockAfter.getLength(),
    });

    return EditorState.forceSelection(newState, endRange);
  }

  return null;
}
