import { getDefaultKeyBinding, KeyBindingUtil } from 'draft-js';

/**
 * @param {object} e
 * @returns {string}
 */
export default function getEditorKeyBinding(e) {
  // ctrl + shift + [key]
  if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.key.toLowerCase() === 'x') {
    return 'strikethrough';
  }
  if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.key.toLowerCase() === 'j') {
    return 'code';
  }
  if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.key.toLowerCase() === 'm') {
    return 'addComment';
  }

  // ctrl + [key]
  if (KeyBindingUtil.hasCommandModifier(e) && e.which === 75) {
    return 'addHyperlink';
  }
  if (KeyBindingUtil.hasCommandModifier(e) && e.key.toLowerCase() === 'j') {
    return 'inline-code';
  }

  return getDefaultKeyBinding(e);
}
