/*
 Recursively finds the DOM Element of the block where the cursor is currently present.
 If not found, returns null.
 */
export default function getSelectedBlockNode(editorState) {
  const hasFocus = editorState.getSelection().getHasFocus();
  if (!hasFocus) {
    return null;
  }
  const selection = window.getSelection();
  if (selection.rangeCount === 0) {
    return null;
  }
  let node = selection.getRangeAt(0).startContainer;
  do {
    if (node.getAttribute && node.getAttribute('data-block') === 'true') {
      return node;
    }
    node = node.parentNode;
  } while (node !== null);
  return null;
}
