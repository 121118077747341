import { convertToRaw } from 'draft-js';
import updateEditorState from '@/editor/actions/updateEditorState';
import addNewBlockAt from '@/editor/utils/addNewBlockAt';

export default function prependNewBlockToContentEditor() {
  return (dispatch, getState) => {
    const editorState = getState().editor.editorState;
    const firstBlockKey = convertToRaw(editorState.getCurrentContent()).blocks[0].key;

    dispatch(
      updateEditorState(addNewBlockAt(editorState, firstBlockKey, undefined, undefined, false)),
    );
  };
}
