import DraftOffsetKey from 'draft-js/lib/DraftOffsetKey';

export default function getOffsetKeysInlineStyle(inlineStyle, editorState) {
  const offsetKeys = [];
  inlineStyle.ranges.forEach((range) => {
    const blockTree = editorState.getBlockTree(range.blockKey);
    blockTree.every((leafSet, ii) => {
      const leavesForLeafSet = leafSet.get('leaves');
      return leavesForLeafSet.every((leaf, jj) => {
        const start = leaf.get('start');
        const end = leaf.get('end');
        if (start === range.start && end === range.end) {
          offsetKeys.push(DraftOffsetKey.encode(range.blockKey, ii, jj));
          return false;
        }
        return true;
      });
    });
  });
  return offsetKeys;
}
