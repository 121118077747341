/**
 * @param {{ type: string, marks: Array }} analysisAssessment
 * @returns {Array<string>|null}
 */
export default function getEditorSeoHighlightStrings({ type, marks }) {
  switch (type) {
    case 'keywordDensity':
      return marks.map((m) => m.paragraph);
    case 'textParagraphTooLong':
      return marks.map((m) => m.paragraph);
    case 'sentenceLengthInText':
      return marks.map((m) => m.sentence);
    case 'passiveVoice':
      return marks.map((m) => m.sentence);
    case 'textTransitionWords':
      return marks.map((m) => m.sentence);
    case 'sentenceBeginnings':
      return marks.map((m) => m.sentence);
    case 'wordComplexity':
      return marks.map((m) => m.sentence);
    case 'keyphraseDistribution':
      return marks.map((m) => m.sentence);
    default:
      return null;
  }
}
