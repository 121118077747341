import { EditorState, Modifier } from 'draft-js';
import getCurrentBlock from '@/editor/utils/getCurrentBlock';

export default function convertAsciiQuotesToSmartQuotes(chars, editorState) {
  if (chars === '"' || chars === "'") {
    const currentBlock = getCurrentBlock(editorState);
    if (currentBlock.getType() === 'code-block') return editorState;

    let smartQuote = '';
    let smartQuoteStart = '‘';
    let smartQuoteEnd = '’';
    if (chars === '"') {
      smartQuoteStart = '“';
      smartQuoteEnd = '”';
    }
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const text = currentBlock.getText();
    const selectionOffset = selectionState.getAnchorOffset();
    const charPeforeInput = text.substring(selectionOffset - 1, selectionOffset);
    if (!charPeforeInput || charPeforeInput === ' ') {
      smartQuote = smartQuoteStart;
    } else {
      smartQuote = smartQuoteEnd;
    }
    if (!selectionState.isCollapsed()) {
      const newContent = Modifier.replaceText(contentState, selectionState, smartQuote);
      return EditorState.push(editorState, newContent, 'insert-characters');
    }
    return EditorState.push(
      editorState,
      Modifier.insertText(contentState, selectionState, smartQuote),
      'transpose-characters',
    );
  }
  return editorState;
}
