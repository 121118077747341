import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Editor,
  EditorState,
  RichUtils,
  convertFromRaw,
  convertToRaw,
  Modifier,
  ContentState,
  SelectionState,
} from 'draft-js';
import { toast } from 'react-toastify';
import urlRegex from 'url-regex';
import updateEditorState, { updateEditorStateAsync } from '@/editor/actions/updateEditorState';
import updateEditorNode from '@/editor/actions/updateEditorNode';
import cleanAllTempEditorComments from '@/editor/actions/cleanAllTempEditorComments';
import {
  Block,
  CUSTOM_BLOCK_STYLES,
  EDITOR_COLLABORATION_EVENT,
  EDITOR_COLLABORATION_WHISPER,
} from '../constants/Constants';
import Sidebar from './sidebar/Sidebar';
import Toolbar from './toolbar/Toolbar';
import BlockToolbar from './blocks/BlockToolbar';
import blockRendererFn from './blocks/blockRendererFn';
import blockStyleFn from './blocks/blockStyleFn';
import getCurrentBlock from '../utils/getCurrentBlock';
import resetBlockWithType from '../utils/resetBlockWithType';
import addNewBlockAt from '../utils/addNewBlockAt';
import unstickyInlineCommentStyle from '../utils/unstickyInlineCommentStyle';
import { getEditorComments } from '../selectors';
import BlockRenderMap from './blocks/BlockRenderMap';
import getDefaultDecorator from '../decorators/getDefaultDecorator';
import DEFAULT_PLUGINS from '../plugins/default';
import convertAsciiQuotesToSmartQuotes from '@/editor/utils/convertAsciiQuotesToSmartQuotes';
import syncEditorComments from '@/editor/utils/syncEditorComments';
import customStyleFnEditorComments from '@/editor/utils/customStyleFnEditorComments';
import scrollToEditorComment from '@/editor/utils/scrollToEditorComment';
import debounce from '@/storychief/utils/debounce';
import BlockToolbarAssistant from './blocks/BlockToolbarAssistant';
import AssistantProvider from '@/assistant/components/AssistantProvider';
import AssistantPlaceholder from '@/assistant/components/AssistantPlaceholder';
import selectedEditorCommentsClean from '@/editor/utils/selectedEditorCommentsClean';
import selectedEditorCommentHighlight from '@/editor/utils/selectedEditorCommentHighlight';
import EditorComments from '@/editor/components/EditorComments';
import toggleCommentInlineStyleAction from '@/editor/actions/toggleCommentInlineStyle';
import isTempEditorCommentCleanOnSelection from '@/editor/utils/isTempEditorCommentCleanOnSelection';
import {
  addLinkToEditorState,
  addPastedURLtoEditorState,
  getEditorStateWithFixedAtomicBlockAfterPaste,
} from '@/storychief/components/editors/Modifiers';
import StoryChief from '@/storychief';
import {
  useDidUpdateEffect,
  useEmitEvent,
  useEmitEventEffect,
  usePrevious,
} from '@/storychief/hooks';
import replaceLineBreakCharacters from '@/editor/utils/replaceLineBreakCharacters';
import getEditorKeyBinding from '@/editor/utils/getEditorKeyBinding';
import handleEditorDeleteKeyCommand from '@/editor/utils/handleEditorDeleteKeyCommand';
import handleEditorBackspaceKeyCommand from '@/editor/utils/handleEditorBackspaceKeyCommand';
import useScrollToEditorHighlight from '@/editor/hooks/useScrollToEditorHighlight';
import getEditorSeoHighlightStrings from '@/editor/utils/getEditorSeoHighlightStrings';
import getEditorSeoHighlightBlocks from '@/editor/utils/getEditorSeoHighlightBlocks';
import useComments from '@/comments/hooks/useComments';
import useSelectedComment from '@/comments/hooks/useSelectedComment';
import EditorScBlockCollaboration from '@/editor/EditorScBlockCollaboration';
import useEditorCollaboration from '@/editor/hooks/useEditorCollaboration';
import useAccount from '@/account/hooks/useAccount';
import getPusherListener from '@/pusher/utils/getPusherListener';
import getEditorCollaborationStorage from '@/editor/utils/getEditorCollaborationStorage';
import setEditorCollaborationStorage from '@/editor/utils/setEditorCollaborationStorage';

const propTypes = {
  disabled: PropTypes.bool,
  preview: PropTypes.bool,
  assistantSubject: PropTypes.shape({
    type: PropTypes.string,
    component: PropTypes.string,
    model: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      brief: PropTypes.shape({}),
      updateTitleEditorState: PropTypes.func,
    }),
    hasSelectedText: PropTypes.bool,
    isEmpty: PropTypes.bool,
    language: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  blockButtons: PropTypes.arrayOf(PropTypes.shape({})),
  inlineButtonsRight: PropTypes.arrayOf(PropTypes.shape({})),
  modelId: PropTypes.string.isRequired,
  modelType: PropTypes.string.isRequired,
  content: PropTypes.string,
  language: PropTypes.string,
  isPrivate: PropTypes.bool,
  editorState: PropTypes.shape({
    getSelection: PropTypes.func.isRequired,
    getCurrentContent: PropTypes.func.isRequired,
  }).isRequired,
  selectedAnalysisAssessment: PropTypes.shape({
    type: PropTypes.string.isRequired,
    marks: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  commentsPositionMode: PropTypes.oneOf(['editor', 'comment']),
  editorComments: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string,
  continuousBlocks: PropTypes.arrayOf(PropTypes.string),
  cleanAllTempEditorComments: PropTypes.func.isRequired,
  updateEditorNode: PropTypes.func.isRequired,
  updateEditorState: PropTypes.func.isRequired,
  updateEditorStateAsync: PropTypes.func.isRequired,
  toggleCommentInlineStyle: PropTypes.func.isRequired,
  sidebars: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ).isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  onEditorStateChange: PropTypes.func,
  plugins: PropTypes.arrayOf(PropTypes.shape({})),
};
const defaultProps = {
  assistantSubject: undefined,
  disabled: false,
  isPrivate: false,
  preview: false,
  content: '',
  language: undefined,
  selectedAnalysisAssessment: null,
  blockButtons: undefined,
  inlineButtonsRight: undefined,
  editorComments: [],
  commentsPositionMode: undefined,
  placeholder: 'Write your article...',
  continuousBlocks: [Block.UNSTYLED, Block.BLOCKQUOTE, Block.OL, Block.UL],
  onEditorStateChange: () => {},
  plugins: DEFAULT_PLUGINS,
};

function EditorScContent(props) {
  // State
  const [readOnly, setReadOnly] = useState(false);
  const [showToolbarHyperlink, setShowToolbarHyperlink] = useState(false);
  const [hasRecentlyPastedText, setHasRecentlyPastedText] = useState(false);
  const { blockTarget, blockedBlocks, hasEditorCollaborationEntitlement } =
    useEditorCollaboration();
  const [isTyping, setIsTyping] = useState(false);

  // Hooks
  const account = useAccount();
  const setIsScrollToHighlight = useScrollToEditorHighlight();
  const { comments } = useComments();
  const { selectedCommentEditorKey, setSelectedCommentEditorKey } = useSelectedComment(comments);

  const prevContent = usePrevious(props.content);
  const prevDisabled = usePrevious(props.disabled);
  const prevSelectedCommentEditorKey = usePrevious(selectedCommentEditorKey);
  const prevSelectedAnalysisAssessment = usePrevious(props.selectedAnalysisAssessment);
  const location = useLocation();
  const emitStartedEditingBlock = useEmitEvent(EDITOR_COLLABORATION_EVENT.emitStartedEditingBlock);

  // Refs
  const editorNode = useRef(null);
  const toolbarsContainerNode = useRef(null);
  const editorStateRef = useRef(props.editorState);
  const typingTimeoutRef = useRef(null);
  const replaceBlockTimeoutRef = useRef(null);

  // Variables
  const currentEditorContent = props.editorState.getCurrentContent();
  const prevEditorContent = usePrevious(currentEditorContent);
  const lineBreakWarningMessage =
    'Line breaks are not allowed and have been removed from the content.';

  // Effects
  useEffect(() => {
    let newEditorState = syncEditorComments(props.editorState, props.editorComments, comments);

    newEditorState = EditorState.createWithContent(
      newEditorState.getCurrentContent(),
      getDefaultDecorator({ editorEnabled: getEditorEnabled() }),
    );

    if (!props.disabled && !props.preview) {
      newEditorState = EditorState.forceSelection(newEditorState, newEditorState.getSelection());
    }

    if (props.selectedAnalysisAssessment) {
      showEditorSeoStatMarks(props);
    }

    if (selectedCommentEditorKey) {
      const editorComment = props.editorComments.find(
        (editorC) => editorC.key === selectedCommentEditorKey,
      );
      if (editorComment) {
        scrollToEditorComment(editorComment, props.editorState);
      }
    }

    if (
      StoryChief.editorSettings.controls.disallow_line_breaks &&
      includesLineBreakCharacters(newEditorState)
    ) {
      newEditorState = EditorState.push(
        newEditorState,
        replaceLineBreakCharacters(newEditorState),
        'replace-text',
      );
      toast.warning(lineBreakWarningMessage);
      props.updateEditorState(newEditorState);
    } else {
      props.updateEditorStateAsync(newEditorState);
    }
  }, []);

  useDidUpdateEffect(() => {
    if (hasContentChanged()) {
      editorStateRef.current = props.editorState;
    }
  }, [hasContentChanged()]);

  useDidUpdateEffect(() => {
    // Move the cursor of the main editor
    // to the same position of the secondary editor when starting
    // editing a block. Both editors have to be aligned since the secondary editor
    // belongs the main editor.
    props.updateEditorState(
      EditorState.forceSelection(props.editorState, props.editorState.getSelection()),
    );
  }, [blockedBlocks.length, getEditorEnabled()]);

  useEffect(() => {
    const { editorState } = props;

    if (
      StoryChief.editorSettings.controls.disallow_line_breaks &&
      hasRecentlyPastedText &&
      includesLineBreakCharacters(editorState)
    ) {
      clearHasRecentlyPastedText();
      props.updateEditorState(
        EditorState.push(editorState, replaceLineBreakCharacters(editorState), 'replace-text'),
      );
      toast.warning(lineBreakWarningMessage);
    }
  }, [
    hasContentChanged(),
    StoryChief.editorSettings.controls.disallow_line_breaks,
    hasRecentlyPastedText,
  ]);

  useEffect(() => {
    const { editorState, editorComments } = props;

    selectedEditorCommentsClean(editorNode.current.editorContainer);

    if (editorComments) {
      const selectedEditorComment = editorComments.find((c) => c.key === selectedCommentEditorKey);
      if (
        isTempEditorCommentCleanOnSelection({ editorComments, selectedCommentEditorKey, comments })
      ) {
        props.cleanAllTempEditorComments(comments);
      }
      selectedEditorCommentHighlight({
        selectedEditorComment,
        editorState,
        isCommentsSidebarOpen: props.sidebars.data.some((s) => s.id === 'CommentsSidebar'),
      });
      if (selectedEditorComment && prevSelectedCommentEditorKey !== selectedCommentEditorKey) {
        scrollToEditorComment(selectedEditorComment, editorState);
      }
    }
  }, [
    hasContentChanged(),
    selectedCommentEditorKey,
    JSON.stringify(props.editorComments),
    JSON.stringify(comments),
    JSON.stringify(props.sidebars.data),
  ]);

  useEmitEventEffect(
    (block) => handleSolveBlockConflict(block),
    EDITOR_COLLABORATION_EVENT.emitSolvedBlockConflict,
  );

  useDidUpdateEffect(() => {
    // When the editor is disabled or in preview mode, the content may be changed outside
    // the editor (eg. when restoring an older version). In such cases, we need to sync the
    // editor state with the new content.
    if ((props.disabled || prevDisabled || props.preview) && props.content !== prevContent) {
      refreshEditorState(true);
    }

    if (prevSelectedAnalysisAssessment !== props.selectedAnalysisAssessment) {
      if (props.selectedAnalysisAssessment) {
        showEditorSeoStatMarks(props);
      } else {
        hideEditorSeoStatMarks(props);
      }
    }
  }, [props.disabled, props.preview, props.content, props.selectedAnalysisAssessment]);

  useEffect(() => {
    //   - Block the blocks for secondary editors when the main editor navigates
    //   through the content
    //   - Don't allow users move the cursor into blocked blocks
    const blockSelectedIsBlocked = blockedBlocks
      .map((b) => b.block)
      .includes(getCursorPositionBlockId());

    if (getEditorEnabled() && !blockSelectedIsBlocked) {
      emitStartedEditingBlock({
        user: StoryChief.user,
        block: getCursorPositionBlockId(),
      });
    }

    if (getEditorEnabled() && !getCursorPositionBlockId()) {
      props.updateEditorState(moveCursorToFirstAllowedBlock());
    }
  }, [getCursorPositionBlockId(), getEditorEnabled()]);

  useEffect(() => {
    if (JSON.stringify(props.content) !== JSON.stringify(prevContent)) {
      refreshEditorState(true);
    }
  }, [location]);

  useDidUpdateEffect(() => {
    // Finish editing block when secondary editor becomes
    // the main editor
    const listener = getPusherListener(props.modelType, props.modelId);

    if (props.disabled && !prevDisabled) {
      listener.whisper(EDITOR_COLLABORATION_WHISPER.finishedEditingBlock, {
        user: StoryChief.user,
      });
    }
  }, [props.disabled]);

  useEffect(() => {
    // Added listeners to replace blocks on main editor when secondaries save changes
    focus();

    const listener = getPusherListener(props.modelType, props.modelId).listenForWhisper(
      EDITOR_COLLABORATION_WHISPER.savedBlockChanges,
      (data) => {
        replaceBlockContentAsync(getEditorState(), data.content.blocks, data.userId);
      },
    );

    return () => {
      if (getCursorPositionBlockId()) {
        // In case there is a leftover with our id, clean them
        listener.whisper(EDITOR_COLLABORATION_WHISPER.finishedEditingBlock, {
          user: StoryChief.user,
        });
      }

      listener.stopListeningForWhisper(EDITOR_COLLABORATION_WHISPER.savedBlockChanges);
    };
  }, []);

  useDidUpdateEffect(() => {
    if (blockTarget && !getEditorEnabled()) {
      focusAtEditingBlockCollaborative();
    }
  }, [blockTarget]);

  // Functions

  function hasContentChanged() {
    return currentEditorContent !== prevEditorContent;
  }

  function focus() {
    editorNode.current.focus();
  }

  const checkIsTypingDebounced = debounce(checkIsTyping, 500);

  function checkIsTyping() {
    setIsTyping(true);

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setIsTyping(false);
    }, 1000);
  }

  function onChange(newEditorState, userId) {
    checkIsTypingDebounced();

    editorStateRef.current = newEditorState;

    if (currentEditorContent !== newEditorState.getCurrentContent()) {
      props.onEditorStateChange(newEditorState, userId);
    }

    return props.updateEditorState(newEditorState);
  }

  function getEditorState() {
    // Use reference to make sure the getter is returning the newest state and avoid stale state.
    return editorStateRef.current;
  }

  function getEditorNode() {
    return editorNode.current;
  }

  function getEditorEnabled() {
    return !props.disabled && !props.preview;
  }

  const updateEditorNodeDebounced = debounce(props.updateEditorNode, 500);

  function getModelId() {
    return props.modelId;
  }

  function includesLineBreakCharacters(editorState) {
    return convertToRaw(editorState.getCurrentContent()).blocks.some((block) =>
      block.text.includes('\n'),
    );
  }

  function clearHasRecentlyPastedText() {
    setHasRecentlyPastedText(false);
  }

  function handleSolveBlockConflict(block) {
    replaceBlockContent(getEditorState(), block.blocks);

    const savedBlocks = getEditorCollaborationStorage(props.modelType);
    if (savedBlocks) {
      const allBlocks = JSON.parse(savedBlocks);

      if (allBlocks[props.modelId]) {
        const modelBlocks = allBlocks[props.modelId];

        delete modelBlocks[block.blocks[0].key];

        if (Object.keys(modelBlocks).length === 0) {
          delete allBlocks[props.modelId];
        }

        setEditorCollaborationStorage(props.modelType, JSON.stringify(allBlocks));
      }
    }
  }

  function setLink(url, urlTargetBlank, urlRelNofollow) {
    const { entityKey, newEditorState } = addLinkToEditorState({
      url,
      urlTargetBlank,
      urlRelNofollow,
      editorState: props.editorState,
    });

    onChange(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey), focus);
  }

  function replaceBlockContentAsync(editorState, newBlockData, userId) {
    if (!isTyping) {
      replaceBlockContent(editorState, newBlockData, userId);
    } else {
      if (replaceBlockTimeoutRef.current) {
        clearTimeout(replaceBlockTimeoutRef.current);
      }
      replaceBlockTimeoutRef.current = setTimeout(() => {
        replaceBlockContent(editorState, newBlockData, userId);
      }, 1000);
    }
  }

  function replaceBlockContent(editorState, newBlockData, userId) {
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();

    const blockKeyToReplace = newBlockData[0].key;
    const blockToReplace = blockMap.get(blockKeyToReplace);

    // Preserve the current selection
    const currentSelection = editorState.getSelection();

    if (blockToReplace) {
      const isEmpty = newBlockData.every((block) => block.type !== 'atomic' && !block.text);
      if (isEmpty) {
        const newBlockMap = blockMap.delete(blockKeyToReplace);
        const newContentState = contentState.merge({
          blockMap: newBlockMap,
        });

        const newEditorState = EditorState.push(editorState, newContentState, 'remove-block');

        // Restore the original selection
        const finalEditorState = EditorState.forceSelection(newEditorState, currentSelection);
        onChange(finalEditorState, userId);
      } else {
        const newContentState = ContentState.createFromBlockArray(
          convertFromRaw({ blocks: newBlockData, entityMap: {} }).getBlocksAsArray(),
        );

        const selectionState = SelectionState.createEmpty(blockKeyToReplace).merge({
          anchorOffset: 0,
          focusOffset: blockToReplace.getLength(),
        });

        const mergedContentState = Modifier.replaceWithFragment(
          contentState,
          selectionState,
          newContentState.getBlockMap(),
        );

        const newEditorState = EditorState.push(editorState, mergedContentState, 'insert-fragment');

        const finalEditorState = EditorState.forceSelection(newEditorState, currentSelection);
        onChange(finalEditorState, userId);
      }
    }
  }

  function setCodeBlock(editorState, currentBlock) {
    const currentText = currentBlock.text;
    const selectionState = editorState.getSelection();
    let selection = selectionState.merge({
      anchorOffset: 0,
      focusOffset: 3,
    });

    const contentWithoutBackticks = Modifier.replaceText(
      editorState.getCurrentContent(),
      selection,
      '',
    );

    let newEditorState = EditorState.push(editorState, contentWithoutBackticks, 'replace-text');
    selection = selection.merge({
      anchorOffset: currentText.length - 3,
      focusOffset: currentText.length - 3,
    });

    newEditorState = EditorState.forceSelection(newEditorState, selection);
    onChange(RichUtils.toggleCode(newEditorState));
  }

  function customStyleFn(style) {
    return customStyleFnEditorComments(style);
  }

  function handleCommandShowToolbarHyperlink(show) {
    setShowToolbarHyperlink(show);
  }

  function addImageBlock(file, placementBlockKey) {
    const type = file.type;
    if (
      type === 'image/jpeg' ||
      type === 'image/jpg' ||
      type === 'image/png' ||
      type === 'image/png' ||
      type === 'image/gif'
    ) {
      onChange(
        addNewBlockAt(getEditorState(), placementBlockKey, 'atomic', {
          src: '',
          type: 'image',
          size: 'regular',
          alignment: '',
          isPrivate: props.isPrivate,
          initialUpload: file,
        }),
      );
    } else {
      // eslint-disable-next-line no-alert
      alert('Only png, jpg and gif images are allowed');
    }
  }

  function handlePastedFiles(files) {
    if (files) {
      const currentBlock = getCurrentBlock(getEditorState());
      addImageBlock(files[0], currentBlock.getKey());
    }
  }

  function handleDroppedFiles(selection, files) {
    if (selection && files) {
      addImageBlock(files[0], selection.getAnchorKey());
    }
  }

  function getCursorPositionBlockId() {
    const selectionState = props.editorState.getSelection();

    const anchorKey = selectionState.getAnchorKey();

    const contentState = props.editorState.getCurrentContent();
    const block = contentState.getBlockForKey(anchorKey);

    if (!blockedBlocks.map((b) => b.block).includes(block.getKey())) {
      return block.getKey();
    }

    return undefined;
  }

  function moveCursorToFirstAllowedBlock() {
    const content = props.editorState.getCurrentContent();
    const blockMap = content.getBlockMap();

    let targetBlockKey = null;

    // Iterate through all blocks to find the first one that is not forbidden
    blockMap.some((block) => {
      const blockKey = block.getKey();
      if (!blockedBlocks.includes(blockKey)) {
        targetBlockKey = blockKey;
        return true;
      }
      return false;
    });

    // If no allowed block is found, return the current editor state
    if (!targetBlockKey) {
      return props.editorState;
    }

    // Create a new selection state with the cursor at the start of the allowed block
    const selection = SelectionState.createEmpty(targetBlockKey);
    const newEditorState = EditorState.forceSelection(props.editorState, selection);

    return newEditorState;
  }

  function handleAddHyperlinkKeyCommand(editorState) {
    const selection = editorState.getSelection();
    const currentBlock = getCurrentBlock(editorState);

    if (selection && currentBlock) {
      const entityKey = currentBlock.getEntityAt(selection.getStartOffset());

      if (!selection.isCollapsed() && !entityKey) {
        handleCommandShowToolbarHyperlink(true);
      }
    }
  }

  function getKeyCommand(command) {
    switch (command) {
      case 'delete':
        return handleEditorDeleteKeyCommand(props.editorState);
      case 'backspace':
        return handleEditorBackspaceKeyCommand(props.editorState);
      case 'addHyperlink':
        return handleAddHyperlinkKeyCommand(props.editorState);
      case 'strikethrough':
        return RichUtils.toggleInlineStyle(props.editorState, 'STRIKETHROUGH');
      case 'addComment':
        return toggleInlineStyle('COMMENT');
      case 'code':
        return RichUtils.toggleBlockType(props.editorState, 'code-block');
      case 'inline-code':
        return RichUtils.toggleInlineStyle(props.editorState, 'CODE');
      default:
        return RichUtils.handleKeyCommand(props.editorState, command);
    }
  }

  function handleKeyCommand(command) {
    if (props.preview || props.disabled) {
      return true;
    }

    const newState = getKeyCommand(command);

    if (newState) {
      onChange(newState);
      return true;
    }

    return false;
  }

  function handlePastedText(text) {
    if (props.preview || props.disabled) {
      return true;
    }

    const { editorState } = props;

    const currentBlock = getCurrentBlock(editorState);
    const blockType = currentBlock.getType();

    setHasRecentlyPastedText(true);

    if (urlRegex({ exact: true }).test(text)) {
      addPastedURLtoEditorState(editorState, text, setLink, onChange);
      return true;
    }
    if (blockType.indexOf('atomic') === 0) {
      onChange(getEditorStateWithFixedAtomicBlockAfterPaste(editorState, text));
      return true;
    }
    return false;
  }

  function handleBeforeInput(chars) {
    if (props.preview || props.disabled) {
      return 'handled';
    }

    const { editorState } = props;
    let newEditorState = editorState;
    newEditorState = unstickyInlineCommentStyle(chars, newEditorState);
    newEditorState = convertAsciiQuotesToSmartQuotes(chars, newEditorState);
    if (editorState !== newEditorState) {
      onChange(newEditorState);
      return 'handled';
    }
    return 'not-handled';
  }

  function handleReturn(e) {
    if (props.preview || props.disabled) {
      return true;
    }

    const { editorState } = props;
    const currentBlock = getCurrentBlock(editorState);
    const currentText = currentBlock.text;
    // checking for back-ticks and code-block style: if found toggle code-block
    const backTicks = '```';
    if (currentText.startsWith(backTicks) && currentBlock.type !== 'code-block') {
      setCodeBlock(editorState, currentBlock);
      return true;
    }

    if (e.shiftKey && !StoryChief.editorSettings.controls.disallow_line_breaks) {
      onChange(RichUtils.insertSoftNewline(editorState));
      return true;
    }

    if (!e.altKey && !e.metaKey && !e.ctrlKey) {
      const blockType = currentBlock.getType();
      if (blockType.indexOf('atomic') === 0) {
        onChange(addNewBlockAt(editorState, currentBlock.getKey()));
        return true;
      }

      if (currentBlock.getLength() === 0) {
        switch (blockType) {
          case Block.UL:
          case Block.OL:
          case Block.BLOCKQUOTE:
          case Block.CAPTION:
          case Block.H2:
          case Block.H3:
          case Block.H4:
          case Block.H1:
          case Block.CODE:
            onChange(resetBlockWithType(editorState, Block.UNSTYLED));
            return true;
          default:
            return false;
        }
      }

      const selection = editorState.getSelection();

      if (selection.isCollapsed() && currentBlock.getLength() === selection.getStartOffset()) {
        if (props.continuousBlocks.indexOf(blockType) < 0) {
          onChange(addNewBlockAt(editorState, currentBlock.getKey()));
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  }

  function toggleBlockType(blockType) {
    const type = RichUtils.getCurrentBlockType(props.editorState);
    if (type.indexOf('atomic') === 0) {
      return;
    }
    onChange(RichUtils.toggleBlockType(props.editorState, blockType));
  }

  function toggleInlineStyle(inlineStyle) {
    const type = RichUtils.getCurrentBlockType(props.editorState);

    if (type.indexOf('header') === 0 && inlineStyle !== 'COMMENT') {
      return;
    }

    if (inlineStyle === 'COMMENT') {
      onChange(
        props.toggleCommentInlineStyle({
          editorState: props.editorState,
          editorComments: props.editorComments,
          comments,
          setSelectedCommentEditorKey,
        }),
      );
    } else {
      onChange(RichUtils.toggleInlineStyle(props.editorState, inlineStyle));
    }
  }

  function refreshEditorState(onlyRefreshInterface) {
    let newEditorState;
    // update content
    if (props.content !== '') {
      newEditorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(props.content)),
        getDefaultDecorator({ editorEnabled: getEditorEnabled() }),
      );
    } else {
      newEditorState = EditorState.createEmpty(
        getDefaultDecorator({ editorEnabled: getEditorEnabled() }),
      );
    }

    newEditorState = syncEditorComments(
      newEditorState,
      getEditorComments({ editor: { editorState: newEditorState } }),
      comments,
    );

    if (!onlyRefreshInterface) {
      props.onEditorStateChange(newEditorState);
    }

    props.updateEditorState(
      EditorState.forceSelection(newEditorState, newEditorState.getSelection()),
    );
  }

  function showEditorSeoStatMarks(_props) {
    const analysisAssessment = _props.selectedAnalysisAssessment;
    const highlightBlocks = getEditorSeoHighlightBlocks(analysisAssessment);
    const highlightStrings = getEditorSeoHighlightStrings(analysisAssessment);

    _props.updateEditorState(
      EditorState.set(_props.editorState, {
        decorator: getDefaultDecorator({
          highlightStrings,
          highlightBlocks,
          editorEnabled: getEditorEnabled(),
        }),
      }),
    );

    if (highlightStrings || highlightBlocks) {
      setIsScrollToHighlight(true);
    }
  }

  function handleOnTempEditorCommentClean() {
    props.cleanAllTempEditorComments(comments);
  }

  function hideEditorSeoStatMarks(_props) {
    _props.updateEditorState(
      EditorState.set(_props.editorState, {
        decorator: getDefaultDecorator({ editorEnabled: getEditorEnabled() }),
      }),
    );
  }

  const currentBlock = getCurrentBlock(props.editorState);

  const setEditorNodeRef = useCallback(
    (node) => {
      editorNode.current = node;
      updateEditorNodeDebounced(node);
    },
    [editorNode],
  );

  function focusAtEditingBlockCollaborative() {
    // When a secondary editor is opened we need to focus the editor manually
    // since the secondary editor belongs the primary editor and both cursors need to be aligned
    const editorState = props.editorState;
    const contentState = editorState.getCurrentContent();
    const block = contentState.getBlockForKey(blockTarget);

    const selection = SelectionState.createEmpty(blockTarget).merge({
      anchorKey: blockTarget,
      anchorOffset: block.getLength(),
      focusKey: blockTarget,
      focusOffset: block.getLength(),
      hasFocus: false,
    });

    const newEditorState = EditorState.forceSelection(editorState, selection);

    props.updateEditorState(newEditorState);
  }

  return (
    <div className="editor-container" data-intercom-target="sc-editor-content">
      <Editor
        ref={setEditorNodeRef}
        webDriverTestID="cy_editor_body"
        readOnly={props.disabled || readOnly}
        placeholder={props.placeholder}
        editorState={props.editorState}
        getEditorState={getEditorState}
        onChange={onChange}
        spellCheck
        blockRendererFn={blockRendererFn(
          onChange,
          getEditorState,
          getModelId,
          setReadOnly,
          setLink,
          getEditorEnabled(),
          props.plugins,
          blockedBlocks,
          props.modelType,
          props.modelId,
          hasEditorCollaborationEntitlement,
        )}
        blockStyleFn={blockStyleFn}
        blockRenderMap={BlockRenderMap}
        customStyleFn={customStyleFn}
        customStyleMap={CUSTOM_BLOCK_STYLES}
        handleKeyCommand={handleKeyCommand}
        handleReturn={handleReturn}
        handlePastedText={handlePastedText}
        handlePastedFiles={handlePastedFiles}
        handleDroppedFiles={handleDroppedFiles}
        handleBeforeInput={handleBeforeInput}
        keyBindingFn={getEditorKeyBinding}
      />

      <EditorScBlockCollaboration modelType={props.modelType} modelId={props.modelId} />

      <div className="editor-toolbars" ref={toolbarsContainerNode}>
        <AssistantProvider
          getEditorState={getEditorState}
          setEditorState={onChange}
          setReadOnly={setReadOnly}
          subject={props.assistantSubject}
        >
          <Toolbar
            toolbarsContainer={toolbarsContainerNode.current}
            editorState={props.editorState}
            setEditorState={onChange}
            toggleBlockType={toggleBlockType}
            toggleInlineStyle={toggleInlineStyle}
            editorEnabled={!props.disabled}
            preview={props.preview}
            setLink={setLink}
            focus={focus}
            blockButtons={props.blockButtons}
            inlineButtonsRight={props.inlineButtonsRight}
            showToolbarHyperlink={showToolbarHyperlink}
            handleCommandShowToolbarHyperlink={handleCommandShowToolbarHyperlink}
            language={props.language}
          />

          {getEditorEnabled() && (
            <>
              {account.can.power_mode && (
                <AssistantPlaceholder getEditorNode={getEditorNode} focusEditor={focus} />
              )}
              <Sidebar
                editorState={props.editorState}
                getEditorState={getEditorState}
                setEditorState={onChange}
                setReadOnly={setReadOnly}
                focus={focus}
                plugins={props.plugins}
                isPrivate={props.isPrivate}
                blockButtons={props.blockButtons}
                toggleBlockType={toggleBlockType}
                toolbarsContainer={toolbarsContainerNode.current}
              />

              {currentBlock && currentBlock.getType() === 'atomic' && (
                <BlockToolbar
                  getEditorNode={getEditorNode}
                  currentBlock={currentBlock}
                  editorSettings={StoryChief.editorSettings}
                  getEditorState={getEditorState}
                  setEditorState={onChange}
                  focus={focus}
                  toolbarsContainer={toolbarsContainerNode.current}
                />
              )}
              {currentBlock && (
                <BlockToolbarAssistant getEditorNode={getEditorNode} currentBlock={currentBlock} />
              )}
            </>
          )}
        </AssistantProvider>

        <EditorComments
          modelType={props.modelType}
          modelId={props.modelId}
          editorComments={props.editorComments}
          onTempEditorCommentClean={handleOnTempEditorCommentClean}
          positionMode={props.commentsPositionMode}
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    editorState: state.editor.editorState,
    editorComments: getEditorComments(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEditorNode,
      updateEditorState,
      updateEditorStateAsync,
      cleanAllTempEditorComments,
      toggleCommentInlineStyle: toggleCommentInlineStyleAction,
    },
    dispatch,
  );
}

EditorScContent.propTypes = propTypes;
EditorScContent.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(EditorScContent);
