import { useEffect, useState } from 'react';
import { animateScroll } from 'react-scroll';

/**
 * @returns {Function} setIsScrollToHighlight
 */
export default function useScrollToEditorHighlight() {
  const [isScrollToHighlight, setIsScrollToHighlight] = useState(false);

  function scrollToHighlight() {
    const highlightElements = document.getElementsByClassName('search-highlight');

    if (highlightElements.length) {
      const highlightElementRec = highlightElements[0].getBoundingClientRect();
      const offset = highlightElementRec.top - window.innerHeight / 3;
      animateScroll.scrollMore(offset);
    }

    setIsScrollToHighlight(false);
  }

  useEffect(() => {
    if (isScrollToHighlight) {
      scrollToHighlight();
    }
  }, [isScrollToHighlight]);

  return setIsScrollToHighlight;
}
