/**
 * @param {object} contentState
 * @param {Function} contentState.getBlocksAsArray
 * @returns {string|null} src
 */
function getContentStateFirstImage(contentState) {
  const blockMap = contentState.getBlocksAsArray();
  const imageBlock = blockMap.find((b) => b.type === 'atomic' && b.data.get('type') === 'image');

  return imageBlock ? imageBlock.data.get('src') : null;
}

export default getContentStateFirstImage;
