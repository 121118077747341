import { cloneDeep } from '@apollo/client/utilities';
import { convertFromRaw, convertToRaw } from 'draft-js';
import generateRandomKey from 'draft-js/lib/generateRandomKey';

/**
 * @param {{ getCurrentContent: Function }} editorState
 * @returns {object}
 */
export default function replaceLineBreakCharacters(editorState) {
  const newBlocks = [];
  const rawContent = convertToRaw(editorState.getCurrentContent());

  rawContent.blocks.forEach((block) => {
    if (block.text.includes('\n')) {
      if (block.type === 'unstyled') {
        const lineTexts = block.text.split('\n');

        lineTexts.forEach((text) => {
          const newBlock = cloneDeep(block);
          newBlock.text = text;
          newBlock.key = generateRandomKey();

          newBlocks.push(newBlock);
        });
      } else {
        const newBlock = cloneDeep(block);
        newBlock.text = block.text.replace(/\n/g, '');
        newBlocks.push(newBlock);
      }
    } else {
      newBlocks.push(block);
    }
  });

  rawContent.blocks = newBlocks;

  return convertFromRaw(rawContent);
}
