import { EditorState, Modifier } from 'draft-js';
/*
  Break out of the inline comment style
 */
export default function unstickyInlineCommentStyle(chars, editorState) {
  const selection = editorState.getSelection();
  if (!selection.isCollapsed()) return editorState;
  const startOffset = selection.getStartOffset();
  const content = editorState.getCurrentContent();
  const block = content.getBlockForKey(selection.getStartKey());
  const entity = block.getEntityAt(startOffset);
  if (entity !== null) return editorState;
  // If we're currently in a style, but the next character doesn't have a style (or doesn't exist)
  // we insert the characters manually without the inline style to 'unsticky' them
  const style = block.getInlineStyleAt(startOffset - 1);
  if (style.size === 0) return editorState;
  const nextStyle = block.getInlineStyleAt(startOffset);
  if (nextStyle.size !== 0) return editorState;
  if (!style.find((s) => s.includes('COMMENT'))) return editorState;
  const newContent = Modifier.insertText(content, selection, chars);
  return EditorState.push(editorState, newContent, 'insert-characters');
}
