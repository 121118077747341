/**
 * @param {HTMLElement} container
 */
export default function selectedEditorCommentsClean(container) {
  if (!container) {
    return;
  }

  const nodes = container.querySelectorAll('span.comment-selected');
  nodes.forEach((el) => el.classList.remove('comment-selected'));
}
