import getOffsetKeysInlineStyle from '@/editor/utils/getOffsetKeysInlineStyle';

export default function selectedEditorCommentHighlight({
  selectedEditorComment,
  editorState,
  isCommentsSidebarOpen,
}) {
  if (
    !selectedEditorComment ||
    !editorState ||
    // Do not highlight resolved comment if comments sidebar is closed
    (selectedEditorComment.resolved && !isCommentsSidebarOpen)
  ) {
    return;
  }

  const offsetKeys = getOffsetKeysInlineStyle(selectedEditorComment, editorState);

  if (offsetKeys.length > 0) {
    offsetKeys.forEach((offsetKey) => {
      const node = document.querySelector(`span[data-offset-key="${offsetKey}"]`);

      if (node) {
        node.classList.add('comment-selected');
      }
    });
  }
}
